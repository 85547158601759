<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div v-if="!loading" class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          รับสินค้าเข้าคลัง
        </h3>
      </div>
      <div class="card-body">
        <div class="row d-flex justify-content-center">
          <div class="col-sm-6">
            <label for="selectMainProduct" class="form-label"
              >เลขที่ใบสั่งซื้อ</label
            >
            <input
              v-model="form.purchase_no"
              disabled
              class="form-control mb-4"
            />
          </div>
          <div class="col-sm-6">
            <label for="selectMainProduct" class="form-label"
              >วันที่สั่งซื้อ</label
            >
            <input
              v-model="form.documentDate"
              disabled
              class="form-control mb-4"
            />
          </div>
          <div class="col-sm-6">
            <label for="selectMainProduct" class="form-label"
              >เลขที่เอกสาร</label
            >
            <input disabled class="form-control mb-4" />
          </div>
          <div class="col-sm-6">
            <label for="selectMainProduct" class="form-label"
              >วันที่เอกสาร</label
            >
            <input
              v-model="form.documentDate"
              disabled
              class="form-control mb-4"
            />
          </div>
          <div class="col-sm-6">
            <label
              for="selectMainProduct"
              class="form-label col-sm-2 text-sm-start text-md-end"
              >รหัสผู้ขาย</label
            >
            <input
              v-model="form.vendor_code"
              disabled
              class="form-control mb-4"
            />
          </div>
          <div class="col-sm-6">
            <label
              for="selectMainProduct"
              class="form-label col-sm-2 text-sm-start text-md-end"
              >ชื่อผู้ขาย</label
            >
            <input
              v-model="form.vendor_name"
              disabled
              class="form-control mb-8"
            />
          </div>
        </div>

        <div class="table-responsive" style="padding-top: 3px">
          <table
            class="table table-hover table-rounded table-striped border gy-7 gs-7"
          >
            <thead>
              <tr
                style="width: 100%"
                class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
              >
                <th>#</th>
                <th>Item code</th>
                <th>Item name</th>
                <th>PO_QTY</th>
                <th>Receive_QTY</th>
                <th>Cost</th>
                <th>Discount</th>
                <th>Vat</th>
                <th>Total</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in form.products" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.product_code }}</td>
                <td>{{ item.product_name }}</td>
                <td>{{ item.item_amt }}</td>
                <td>{{ item.item_amt_recieve }}</td>
                <td>{{ item.product_cost_price }}</td>
                <td>{{ item.item_discount ? item.item_discount : 0 }}</td>
                <!-- <td>
                  {{
                    ((parseInt(
                      item.product_cost_price ? item.product_cost_price : 0
                    ) -
                      parseInt(item.item_discount ? item.item_discount : 0)) *
                      parseInt(item.item_amt ? item.item_amt : 0) *
                      7) /
                    100
                  }}
                </td> -->
                <td>
                  {{
                    form.tax_type === "0"
                      ? 0
                      : ((parseFloat(
                          item.product_cost_price ? item.product_cost_price : 0
                        ) -
                          parseFloat(
                            item.item_discount ? item.item_discount : 0
                          )) *
                          parseFloat(item.item_amt ? item.item_amt : 0) *
                          7) /
                        100
                  }}
                </td>
                <td>
                  {{
                    // form.tax_type === "0"
                    //   ? (parseFloat(
                    //       item.product_cost_price ? item.product_cost_price : 0
                    //     ) -
                    //       parseFloat(
                    //         item.item_discount ? item.item_discount : 0
                    //       )) *
                    //     parseFloat(item.item_amt ? item.item_amt : 0)
                    //   : form.tax_type === "1"
                    //   ? (parseFloat(
                    //       item.product_cost_price ? item.product_cost_price : 0
                    //     ) -
                    //       parseFloat(
                    //         item.item_discount ? item.item_discount : 0
                    //       )) *
                    //       parseFloat(item.item_amt ? item.item_amt : 0) -
                    //     ((parseFloat(
                    //       item.product_cost_price ? item.product_cost_price : 0
                    //     ) -
                    //       parseFloat(
                    //         item.item_discount ? item.item_discount : 0
                    //       )) *
                    //       parseFloat(item.item_amt ? item.item_amt : 0) *
                    //       7) /
                    //       100
                    //   : (parseFloat(
                    //       item.product_cost_price ? item.product_cost_price : 0
                    //     ) -
                    //       parseFloat(
                    //         item.item_discount ? item.item_discount : 0
                    //       )) *
                    //     parseFloat(item.item_amt ? item.item_amt : 0)
                    (parseFloat(
                      item.product_cost_price ? item.product_cost_price : 0
                    ) -
                      parseFloat(item.item_discount ? item.item_discount : 0)) *
                    parseFloat(item.item_amt ? item.item_amt : 0)
                  }}
                </td>

                <td>
                  <div class="row">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success p-0"
                      @click="goToEditProduct(index)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                    <DialogEditPd
                      :dialogEditPd="dialogEditPd"
                      :dataEditItem="dataEditItem"
                      @submitEditItem="submitEditItem"
                      @closeDialogEditPd="closeDialogEditPd"
                      @getOne="getOne"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row d-flex justify-content-center mt-4">
          <div class="col-sm-8 mb-4">
            <label for="selectMainProduct" class="form-label">หมายเหตุ</label>
            <input v-model="form.note" class="form-control mb-4" />
            <label for="selectMainProduct" class="form-label required"
              >โอนเข้าคลัง</label
            >
            <select
              class="form-select mb-4"
              v-model="form.warehouseId"
              id="validationTooltip01"
              required
            >
              <option v-for="item in warehouses" :key="item" :value="item.id">
                {{ item.warehouse_code }} |
                {{ item.warehouse_name }}
              </option>
            </select>
          </div>

          <div class="col-sm-4">
            <label for="selectMainProduct" class="form-label"
              >มูลค่ารวม(ก่อนVAT)</label
            >
            <input
              v-model="form.total_price_before_vat"
              disabled
              class="form-control mb-4"
            />

            <label for="selectMainProduct" class="form-label">ส่วนลดรวม</label>
            <input v-model="form.discount" disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label"
              >รวมภาษีมูลค่าเพิ่ม</label
            >
            <input
              v-model="form.total_price_include_vat"
              disabled
              class="form-control mb-4"
            />

            <label for="selectMainProduct" class="form-label"
              >มูลค่าสุทธิ</label
            >
            <input
              v-model="form.total_price_summary"
              disabled
              class="form-control"
            />
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="mt-2 mb-6 ms-7 row d-flex justify-content-center">
        <div class="card-toolbar ps-0 col-sm-12 d-flex justify-content-start">
          <button
            type="submit"
            class="btn btn-sm btn-light btn-primary ms-3 me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submit"
            :disabled="loadingUpdate"
          >
            <div
              v-if="loadingUpdate"
              class="spinner-grow spinner-grow-sm text-light"
              role="status"
            ></div>
            บันทึก
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            style="font-size: 14px; font-weight: bold"
            @click="cancel"
          >
            ยกเลิก
          </button>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import DialogEditPd from "./components/dialogEditProduct.vue";

import Swal from "sweetalert2/dist/sweetalert2.js";
import Loader from "../../components/loaderUpdate.vue";

import purchaseApi from "@/api/purchase/";
import whApi from "@/api/warehouse/";
import moment from "moment";

export default {
  components: { DialogEditPd, Loader },
  setup() {
    document.title = "J&N | รับสินค้าเข้าคลัง";
  },
  data: () => ({
    dialogEditPd: false,

    loading: false,
    isSubmit: false,
    loadingUpdate: false,

    dialogAddItem: false,
    dialogSalesman: false,

    dataEditItem: {},
    indexEditItem: 0,
    form: {},

    warehouses: [],

    statusAllProcess: 1,
  }),

  created() {
    this.getOne();
    this.getWarehouse();
  },

  methods: {
    async getOne() {
      this.loading = true;
      let getOneResponse = {};
      try {
        getOneResponse = await purchaseApi.purchaseOrder.getOne(
          this.$route.query.id
        );
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        await this.bindingForm(getOneResponse.data);
      }

      this.loading = false;
    },

    async bindingForm(item) {
      this.form = {
        ...item,
        products: [],
      };
      await item.purchaseOrderItems.forEach((element) => {
        this.form.products.push({
          ...element.product,
          productId: element.product.id,
          id: element.id,
          item_amt: element.item_amt,
          item_amt_recieve: element.item_amt_recieve,
          item_discount: element.item_discount,
          id_purchase_order: this.$route.query.id,
          item_id: element.id,
          purchase_item_code: element.purchase_item_code,
        });
      });
      this.form.vendor_name = item.vendor.vendor_name;
      this.form.documentDate = item.createdAt.substring(0, 10);
      // this.taxTypeChange();
    },

    async getWarehouse() {
      let responseData = [];
      try {
        responseData = await whApi.warehouse.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.warehouses = responseData.data;
        this.form.warehouseId = this.warehouses[0].id;
      }
    },

    async submit() {
      let updateResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      this.form = {
        ...this.form,
        status: "3",
        companyId: parseInt(localStorage.getItem("companyId")),
        receive_date: moment(new Date()).format(),
      };
      if (this.isValid) {
        this.loadingUpdate = true;
        updateResponse = await purchaseApi.purchaseOrder.update(
          this.$route.query.id,
          this.form
        );
        if (updateResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: `รับสินค้าเข้าคลังสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.updateProduct();
          });
          this.loadingUpdate = false;
        } else {
          Swal.fire({
            icon: "error",
            title: "รับสินค้าเข้าคลังไม่สำเร็จ !",
            showConfirmButton: false,
            timer: 1500,
          });
          this.loadingUpdate = false;
        }
      }
    },

    // async submit() {
    //   await this.validateForm();
    //   await this.checkFormIsEmpty();
    //   this.updateProduct();
    // },

    // update item
    async updateProduct() {
      let countArr = 0;
      for (const element of this.form.purchaseOrderItems) {
        countArr += 1;
        await this.getOneProduct(element.product.id, {
          item_amt: element.item_amt,
          item_amt_recieve: element.item_amt_recieve,
          item_amt_old_recieve: element.item_amt_old_recieve
            ? element.item_amt
            : 0,
          item_amt_new_recieve: element.item_amt_new_recieve
            ? element.item_amt
            : 0,
        });
      }
      if (
        countArr === this.form.purchaseOrderItems.length &&
        this.statusAllProcess === 1
      ) {
        this.$router.push({
          path: "/purchase/PU2-3",
          query: {},
        });
      }
    },
    async getOneProduct(id, amountItem) {
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.product.getOne(id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.updateProductAmountRecieve(amountItem, getOneResponse.data);
      }
    },
    async updateProductAmountRecieve(amountItem, item) {
      let updateResponse = [];
      let sumQty = 0;
      let inTransit = 0;

      if (
        amountItem.item_amt_old_recieve === 0 &&
        amountItem.item_amt_new_recieve === 0
      ) {
        // sumQty = amountItem.item_amt;
        sumQty = amountItem.item_amt_recieve;
      } else {
        sumQty =
          parseFloat(
            amountItem.item_amt_old_recieve
              ? amountItem.item_amt_old_recieve
              : 0
          ) +
          parseFloat(
            amountItem.item_amt_new_recieve
              ? amountItem.item_amt_new_recieve
              : 0
          );
      }

      if (
        amountItem.item_amt_old_recieve === 0 &&
        amountItem.item_amt_new_recieve === 0
      ) {
        inTransit =
          parseFloat(amountItem.item_amt) -
          parseFloat(amountItem.item_amt_recieve);
      } else {
        inTransit =
          parseFloat(amountItem.item_amt ? amountItem.item_amt : 0) -
          parseFloat(
            amountItem.item_amt_recieve ? amountItem.item_amt_recieve : 0
          );
      }

      const body = {
        ...item,
        product_bring_qty: sumQty,
        product_in_transit: inTransit,
      };

      updateResponse = await whApi.product.update(item.id, body);
      if (updateResponse.response_status !== "SUCCESS") {
        this.statusAllProcess *= 0;

        Swal.fire({
          icon: "error",
          html: `สินค้ารหัส : ${item.product_code} จำนวน: ${amountRecieve} รับเข้าคลัง ไม่สำเร็จ !`,
          timer: 4000,
        });
      } else {
        this.statusAllProcess *= 1;
      }
    },
    // update item

    checkFormIsEmpty() {
      !this.form.warehouseId ? (this.isValid = false) : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.go(-1);
    },

    // product
    goToEditProduct(index) {
      this.dataEditItem = this.form.products[index];
      this.dialogEditPd = true;
    },
    submitEditItem(item) {
      this.form.products.splice(this.indexEditItem, 1, item);
    },
    closeDialogEditPd() {
      this.dialogEditPd = false;
    },

    //total
    taxTypeChange(val) {
      if (this.form.products.length) {
        if (this.form.tax_type === "0") {
          this.taxNotIncluded();
        }
        if (this.form.tax_type === "1") {
          this.taxIncluded();
        }
        if (this.form.tax_type === "2") {
          this.taxSeparate();
        }
      } else {
        this.form.total_price_before_vat = 0;
        this.form.total_price_include_vat = 0;
        this.form.total_price_summary = 0;
        this.form.final_price = 0;
      }
    },

    taxNotIncluded() {
      let total = 0;
      let vat = 0;

      this.form.products.forEach((element) => {
        if (element.product_cost_price && element.item_discount) {
          total +=
            (parseFloat(element.product_cost_price) -
              parseFloat(element.item_discount)) *
            parseFloat(element.item_amt);
          vat +=
            ((parseFloat(element.product_cost_price) -
              parseFloat(element.item_discount)) *
              parseFloat(element.item_amt) *
              7) /
            100;
        } else if (element.product_cost_price && !element.item_discount) {
          total +=
            parseFloat(element.product_cost_price) *
            parseFloat(element.item_amt);
          vat +=
            (parseFloat(element.product_cost_price) *
              parseFloat(element.item_amt) *
              7) /
            100;
        }
      });

      if (this.form.discount) {
        this.form.total_price_before_vat =
          parseFloat(total) - parseFloat(this.form.discount);

        this.form.total_price_include_vat = 0;

        this.form.total_price_summary =
          parseFloat(total) - parseFloat(this.form.discount);

        this.form.final_price =
          parseFloat(total) - parseFloat(this.form.discount);
      } else {
        this.form.total_price_before_vat = parseFloat(total);

        this.form.total_price_include_vat = 0;

        this.form.total_price_summary = parseFloat(total);

        this.form.final_price = parseFloat(total);
      }
    },

    taxIncluded() {
      let total = 0;
      let vat = 0;

      this.form.products.forEach((element) => {
        if (element.product_cost_price && element.item_discount) {
          total +=
            (parseFloat(element.product_cost_price) -
              parseFloat(element.item_discount)) *
            parseFloat(element.item_amt);
          vat +=
            ((parseFloat(element.product_cost_price) -
              parseFloat(element.item_discount)) *
              parseFloat(element.item_amt) *
              7) /
            100;
        } else if (element.product_cost_price && !element.item_discount) {
          total +=
            parseFloat(element.product_cost_price) *
            parseFloat(element.item_amt);
          vat +=
            (parseFloat(element.product_cost_price) *
              parseFloat(element.item_amt) *
              7) /
            100;
        }
      });

      if (this.form.discount) {
        this.form.final_price =
          parseFloat(total) - parseFloat(this.form.discount);
        vat = (parseFloat(this.form.final_price) * 7) / 100;

        this.form.total_price_before_vat =
          parseFloat(total) - parseFloat(this.form.discount) - parseFloat(vat);

        this.form.total_price_include_vat = parseFloat(vat);

        this.form.total_price_summary =
          parseFloat(total) - parseFloat(this.form.discount);
      } else {
        this.form.total_price_before_vat = parseFloat(total) - parseFloat(vat);

        this.form.total_price_include_vat = parseFloat(vat);

        this.form.total_price_summary = parseFloat(total);

        this.form.final_price = parseFloat(total);
      }
    },

    taxSeparate() {
      let total = 0;
      let vat = 0;

      this.form.products.forEach((element) => {
        if (element.product_cost_price && element.item_discount) {
          total +=
            (parseFloat(element.product_cost_price) -
              parseFloat(element.item_discount)) *
            parseFloat(element.item_amt);
          vat +=
            ((parseFloat(element.product_cost_price) -
              parseFloat(element.item_discount)) *
              parseFloat(element.item_amt) *
              7) /
            100;
        } else if (element.product_cost_price && !element.item_discount) {
          total +=
            parseFloat(element.product_cost_price) *
            parseFloat(element.item_amt);
          vat +=
            (parseFloat(element.product_cost_price) *
              parseFloat(element.item_amt) *
              7) /
            100;
        }
      });

      if (this.form.discount) {
        vat = ((parseFloat(total) - parseFloat(this.form.discount)) * 7) / 100;

        this.form.final_price =
          parseFloat(total) + parseFloat(vat) - parseFloat(this.form.discount);

        this.form.total_price_before_vat =
          parseFloat(total) - parseFloat(this.form.discount);

        this.form.total_price_include_vat = parseFloat(vat);

        this.form.total_price_summary =
          parseFloat(total) + parseFloat(vat) - parseFloat(this.form.discount);
      } else {
        vat = (parseFloat(total) * 7) / 100;

        this.form.final_price = parseFloat(total) + parseFloat(vat);

        this.form.total_price_before_vat = parseFloat(total);

        this.form.total_price_include_vat = parseFloat(vat);

        this.form.total_price_summary = parseFloat(total) + parseFloat(vat);
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
</style>
